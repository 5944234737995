export const AUTH_SESSION_NAME = process.env.REACT_APP_AUTH_SESSION_NAME || 'bf-auth';
export const BANK_SESSION_NAME = process.env.REACT_APP_BANK_SESSION_NAME || 'bf-bank';
export const PRODUCTION = (process.env.REACT_APP_PRODUCTION || 'false').toLowerCase() === 'true';
export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '6LeTjekhAAAAAPAATwsADP0By0DK3An2Xe27BSpA';
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyBwWzSHzUG7RIag9veb4st7w0vlWBg_0cM';
export const FIREBASE_AUTH_DOMAIN = `${(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'bossfighters-dev')}.firebaseapp.com`;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID || 'bossfighters-dev';
export const FIREBASE_STORAGE_BUCKET = `${(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'bossfighters-dev')}.appspot.com`;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '751000542123';
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID || '1:751000542123:web:e01b3e3093342dd6a510ee';
// export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_SPACE_GOOGLE_ANALYTICS_ID || 'G-PSGSY6E6J1';
// export const PRODUCTION_SOLANA_RPC_ENDPOINT = process.env.REACT_APP_PRODUCTION_SOLANA_RPC_ENDPOINT || 'https://api.mainnet-beta.solana.com';