import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import Perk, {EditPerk, PerkValues, RarityAcquisition} from '../../../types/api/Game/Perk';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';
import MessageResponse from '../../../types/api/MessageResponse';
import IDropdownResults from '../../../types/api/Admin/GameSessions/DropdownResults';
import {CompBulkActions} from '../../../types/api/Game/GameplayTask';

export async function getFilterPerks(
  page: number = 1, perPage: number = 20, {name, type, categories, inventoryClassIds}: PerkValues
): Promise<AxiosResponse<PaginatedResponse<Perk>>> {
  return makeRequest('POST', '/api/admin/game/perks/filter', {
    page, perPage, name, type: type?.id ?? undefined, categories, inventoryClassIds,
  });
}

export async function getRarityAcquisitions(): Promise<AxiosResponse<RarityAcquisition[]>> {
  return makeRequest('GET', '/api/admin/game/rarity-acquisitions');
}

export async function getRarityAcquisitionTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/rarity-acquisitions/types');
}

export async function deleteRarity(id: string) {
  return makeRequest('DELETE', `/api/admin/game/rarity-acquisitions/${id}`);
}

export async function getRarityLevels(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/perks/levels');
}

export async function createRarityAcquisitions(rarity: number, type: number, probabilities?: number[][]): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', '/api/admin/game/rarity-acquisitions', {rarity, type, probabilities});
}

export async function updateRarityAcquisitions(id: string, rarity: number, type: number, probabilities?: number[][]): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/game/rarity-acquisitions/${id}`, {rarity, type, probabilities});
}


export async function createPerk({
  type, category, characterAttributes,
  name, description, weight,
}: EditPerk): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', '/api/admin/game/perks', {
    type: type.value, category: category.value,
    characterAttributes, name, description, weight,
  });
}

export async function updatePerk(id: string, {
  type, category, characterAttributes,
  name, description, weight,
}: EditPerk): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/game/perks/${id}`, {
    type: type.value, category: category.value,
    characterAttributes, name, description, weight,
  });
}

export async function deletePerk(id: string) {
  return makeRequest('DELETE', `/api/admin/game/perks/${id}`);
}

export async function searchBalanceParametersByName(name: string): Promise<AxiosResponse<IDropdownResults[]>> {
  return await makeRequest('GET', `/api/admin/game/balance/parameters/search?name=${name}`);
}

export async function getPerkTypes() {
  return makeRequest('GET', '/api/admin/game/perks/types');
}

export async function savePerkCompatibilities(
  perkId: string, compatibilities: { inventoryClassId: string, compatible: boolean }[]
) {
  return makeRequest('POST', `/api/admin/game/perks/${perkId}/compatibilities`, {compatibilities});
}

export async function getInventoryCategories(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/perks/inventory-categories');
}

export async function getCompBulkActions(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/perks/compatibilities-bulk-actions');
}

export async function saveCompatibilitiesBulkActions({
  action, ids, compatibilities, repCompatibilities, withComp
}: CompBulkActions,) {
  return makeRequest('POST', '/api/admin/game/perks/compatibilities-bulk-actions', {
    action, perkIds: ids,
    compatibilities: compatibilities ? compatibilities.map(item => ({
      inventoryClassId: item.inventoryClassId?.value,
      compatible: withComp ? item?.compatible : undefined
    })) :
      repCompatibilities ? repCompatibilities.map(item => ({
        inventoryClassId: item.inventoryClassId?.value,
        replaceInventoryClassId: item.replaceInventoryClassId?.value,
        compatible: item.compatible,
      })) : undefined,
  });
}