import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import UserInformation from '../../../types/api/Admin/Users/UserInformation';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import UserOptions from '../../../types/api/Admin/Users/UserOptions';
import MessageResponse from '../../../types/api/MessageResponse';
import UserFiltersSelected from '../../../types/api/Admin/Users/UserFiltersSelected';
import {USER_FILTERS, USER_FILTERS_NAMES} from '../../../types/api/constants';
import IDropdownResults from '../../../types/api/Admin/GameSessions/DropdownResults';
import UserInfoById from '../../../types/api/Admin/Users/UserInfoById';

export async function list(
  page: number = 1,
  perPage: number = 20,
  filters?: UserFiltersSelected,
): Promise<AxiosResponse<PaginatedResponse<UserInformation>>> {
  if (filters && Object.keys(filters).length) {
    return listFiltered(page, perPage, filters);
  }
  return makeRequest('GET', `/api/admin/users?page=${page}&perPage=${perPage}`);
}

export async function searchByEmail(email: string): Promise<AxiosResponse<IDropdownResults[]>> {
  return makeRequest('GET', `/api/admin/users/dropdown-results?email=${email}`);
}

export async function emailsByUserIds(usersIds: string[]): Promise<AxiosResponse<IDropdownResults[]>> {
  return makeRequest('GET', `/api/admin/users/dropdown-results?userIds=${usersIds}`);
}

async function listFiltered(
  page: number = 1,
  perPage: number = 20,
  filters: UserFiltersSelected,
): Promise<AxiosResponse<PaginatedResponse<UserInformation>>> {
  const body: {
        [key: string]: string[];
    } = {};
  for (const key of Object.keys(filters)) {
    // @ts-ignore
    if (!USER_FILTERS[key]) {
      continue;
    }
    // @ts-ignore
    body[USER_FILTERS_NAMES[USER_FILTERS[key]]] = filters[key];
  }

  return makeRequest(
    'POST',
    `/api/admin/users/filter?page=${page}&perPage=${perPage}`,
    body,
  );
}

export async function getUserStatuses(): Promise<AxiosResponse<UserOptions[]>> {
  return makeRequest('GET', '/api/admin/users/statuses');
}

export async function updateUser(
  userId: string,
  {
    statusId,
    rolesToAdd = [],
    userRolesToDrop = [],
    accessType,
  }: {
        statusId?: string;
        rolesToAdd: string[];
        userRolesToDrop: string[];
        accessType?: number
    },
): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/users/${userId}`, {
    statusId,
    rolesToAdd,
    userRolesToDrop,
    accessType,
  });
}

export async function prepareUsersAssignment(
  filtersValues: UserFiltersSelected,
  selectedIds: string[],
  email: string,
) {
  if (selectedIds.length) {
    return makeRequest('POST', '/api/admin/users/assign-keys/prepare', {
      'selectedIds': selectedIds,
    });
  } else {
    return makeRequest('POST', '/api/admin/users/assign-keys/prepare', {
      'email': email,
      'status': filtersValues?.STATUS,
      'role': filtersValues?.ROLE,
    });
  }
}

export async function usersAssignKeys(
  ids: string[],
  hash: string,
  nonce: string,
) {
  return makeRequest('POST', '/api/admin/users/assign-keys', {
    'ids': ids,
    'hash': hash,
    'nonce': nonce,
  });
}

export async function deleteUser(userId: string): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('DELETE', `/api/admin/users/${userId}`);
}

export async function getAccessTypes() {
  return await makeRequest('GET', '/api/admin/game/accounts/access-types');
}

export async function getAll(): Promise<AxiosResponse<UserOptions[]>> {
  return makeRequest('GET', '/api/admin/roles/all');
}

export async function getUserInfo(userId: string): Promise<AxiosResponse<UserInfoById>> {
  return makeRequest('GET', `/api/admin/users/${userId}/info`);
}