import axios, {AxiosError, AxiosResponse} from 'axios';

import makeRequest from '../../request';
import error from '../../errors';

import config from '../../config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import AlphaKeyOwned from '../../../types/api/Admin/AlphaKeys/AlphaKeyOwnedUser';
import UnassignedAlphaKeys from '../../../types/api/Admin/UnassignedAlphaKeys';
import AlphaKeyRequest, {AlphaKeyValues} from '../../../types/api/Admin/AlphaKeys/AlphaKey';

export async function getUnassignedAlphaKeys(): Promise<AxiosResponse<UnassignedAlphaKeys>> {
  return makeRequest('GET', '/api/admin/alpha-keys/status');
}

export async function getAlphaKeysFilter(page: number = 1, perPage: number = 20, {
  start,
  end,
  userIds,
  creatorIds,
}: AlphaKeyValues): Promise<AxiosResponse<PaginatedResponse<AlphaKeyRequest>>> {
  return makeRequest('POST', '/api/admin/alpha-keys/filter', {
    page: page,
    perPage: perPage,
    start: start,
    end: end,
    userIds: userIds,
    creatorIds: creatorIds,
  });
}

export async function generateAlphaKeys(amount: number): Promise<AxiosResponse<{ keys: string[] }>> {
  return makeRequest('POST', '/api/admin/alpha-keys', {amount});
}