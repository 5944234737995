import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import ActivityLog, {ActivityLogValues} from '../../../types/api/Admin/Users/ActivityLog';
import IDropdownItems from '../../../types/api/Admin/DropdownItems';

export async function usersActivityLog(
  page: number = 1, perPage: number = 20, {platformTypes, types, userIds, from, to}: ActivityLogValues,
): Promise<AxiosResponse<PaginatedResponse<ActivityLog>>> {
  return makeRequest('POST', '/api/admin/users/activity-log', {
    page: page,
    perPage: perPage,
    platformTypes: platformTypes,
    types: types,
    userIds: userIds,
    from: from,
    to: to,
  });
}

export async function activityLogTypes(): Promise<AxiosResponse<IDropdownItems[]>> {
  return makeRequest('GET', '/api/admin/users/activity-log/types');
}

export async function activityLogPlatformTypes(): Promise<AxiosResponse<IDropdownItems[]>> {
  return makeRequest('GET', '/api/admin/users/activity-log/platform-types');
}