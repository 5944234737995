import makeRequest from './request';

import {TASK_STATUSES} from './constants';

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export default async function pollTask(pollingPath: string) {
  let {status} = await getTask(pollingPath);
  while (status === TASK_STATUSES.STARTED) {
    await sleep(1500);
    const task = await getTask(pollingPath);
    status = task.status;
  }
  return status;
}

export async function getTask(pollingPath: string) {
  const taskResultResponse = await makeRequest('GET', pollingPath);
  if (taskResultResponse.status !== 200) {
    throw new Error('Task result check failed');
  }
  return taskResultResponse.data;
}
