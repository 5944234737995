import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import Tournaments from '../../../types/api/Admin/Tournaments/Tournaments';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import UserTournament, {IUserTournamentNomination} from '../../../types/api/Admin/Users/UserTournament';

export async function getTournaments(
  page: number = 1,
  perPage: number = 20,
): Promise<AxiosResponse<PaginatedResponse<Tournaments>>> {
  return makeRequest('GET', `/api/admin/game/tournaments?page=${page}&perPage=${perPage}`);
}

export async function createTournament(name: string, start: number, end: number) {
  return makeRequest('POST', '/api/admin/game/tournaments', {name, start, end});
}

export async function updateTournament(tournamentId: string, name: string, start: number, end: number) {
  return makeRequest('POST', `/api/admin/game/tournaments/${tournamentId}`, {name, start, end});
}

export async function deleteTournament(tournamentId: string, force: boolean = false) {
  return makeRequest('DELETE', `/api/admin/game/tournaments/${tournamentId}`, {force});
}

export async function createTournamentNominations(tournamentId: string, statisticId: string, name: string, description: string, winners: number) {
  return makeRequest('POST', '/api/admin/game/tournament-nominations', {
    tournamentId,
    statisticId,
    name,
    description,
    winners,
  });
}

export async function updateTournamentNominations(tournamentId: string, statisticId: string, name: string, description: string, winners: number, force: boolean = false) {
  return makeRequest('POST', `/api/admin/game/tournament-nominations/${tournamentId}`, {
    statisticId,
    name,
    description,
    winners,
    force,
  });
}

export async function deleteTournamentNominations(tournamentId: string, force: boolean = false) {
  return makeRequest('DELETE', `/api/admin/game/tournament-nominations/${tournamentId}`, {force});
}

export async function createTimeslot(tournamentId: string, start: number, end: number) {
  return makeRequest('POST', `/api/admin/game/tournaments/${tournamentId}/timeslots`, {start, end});
}

export async function updateTimeslot(tournamentId: string, timeslotId: string, start: number, end: number) {
  return makeRequest('POST', `/api/admin/game/tournaments/${tournamentId}/timeslots/${timeslotId}`, {start, end});
}

export async function deleteTimeslot(tournamentId: string, timeslotId: string) {
  return makeRequest('DELETE', `/api/admin/game/tournaments/${tournamentId}/timeslots/${timeslotId}`);
}

export async function getUserTournament(userId: string, page: number = 1, perPage: number = 20): Promise<AxiosResponse<PaginatedResponse<UserTournament>>> {
  return await makeRequest('GET', `/api/admin/game/user-tournaments/${userId}?page=${page}&perPage=${perPage}`);
}

export async function getUserTournamentNominations(userId: string, tournamentId: string): Promise<AxiosResponse<IUserTournamentNomination[]>> {
  return await makeRequest('GET', `/api/admin/game/user-tournaments/${userId}/nominations?tournamentId=${tournamentId}`);
}