import axios, {AxiosError, AxiosResponse} from 'axios';

import makeRequest from '../../request';

import CurrencyPair, {CurrencyIcon, IEditCurrencyPair, IGrantState} from '../../../types/api/Admin/Economy/Economy';
import ItemTypes from '../../../types/api/Admin/ItemTypes';
import config from '../../config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';
import error from '../../errors';

export async function getPairs(): Promise<AxiosResponse<CurrencyPair[]>> {
  return makeRequest('GET', '/api/admin/currencies/pairs');
}

export async function getCurrencies(): Promise<AxiosResponse<ItemTypes[]>> {
  return makeRequest('GET', '/api/admin/currencies');
}

export async function getCostCurrencies(): Promise<AxiosResponse<ItemTypes[]>> {
  return makeRequest('GET', '/api/admin/cost-currencies');
}

export async function getIcons(): Promise<AxiosResponse<CurrencyIcon[]>> {
  return makeRequest('GET', '/api/admin/currencies/icons');
}

export async function createPair({currencyFrom, currencyTo, amountFrom, amountTo}: IEditCurrencyPair) {
  return makeRequest('POST', '/api/admin/currencies/pairs', {currencyFrom, currencyTo, amountFrom, amountTo});
}

export async function updatePair({currencyFrom, currencyTo, amountFrom, amountTo}: IEditCurrencyPair) {
  return makeRequest('POST', '/api/admin/currencies/pairs/update', {currencyFrom, currencyTo, amountFrom, amountTo});
}

export async function deletePair(currencyFrom: number, currencyTo: number) {
  await makeRequest('DELETE', '/api/admin/currencies/pairs', {currencyFrom, currencyTo});
}

export async function setIcon(
  currency: number,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/admin/currencies/${currency}/icons`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function grantCurrency({currency, amount, user}: IGrantState) {
  return makeRequest('POST', '/api/admin/currencies/grant', {currency, amount, userId: user?.value ?? ''});
}