import axios, {AxiosError, AxiosResponse} from 'axios';

import makeRequest from '../../request';
import error from '../../errors';

import config from '../../config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';

import Achievements from '../../../types/api/Admin/Achievements/Achievements';

export async function getAchievements(): Promise<AxiosResponse<Achievements[]>> {
  return await makeRequest('GET', '/api/admin/achievements');
}

export async function updateAchievement(
  achievementId: string,
  exp: number,
  name: string,
  enabled: boolean,
  description: string,
): Promise<AxiosResponse<{ message: string }>> {
  return makeRequest('POST', `/api/admin/achievements/${achievementId}`,
    {exp, name, enabled, description});
}

export async function updateAchievementImage(
  achievementId: string,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/admin/achievements/${achievementId}/image`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function updateInactiveAchievementImage(
  achievementId: string,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/admin/achievements/${achievementId}/inactive-image`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}