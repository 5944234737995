export class HTTPError extends Error {
  status: number;
  code?: string;

  constructor(status: number, message?: string, code?: string) {
    super(message);
    this.status = status;
    this.code = code;
  }
}

export class ServerInternalError extends HTTPError {
}

export class ManyRequests extends HTTPError {
}

export class Unauthorized extends HTTPError {
}

export class OpenlootAccessExpiredError extends HTTPError {
}

export class Forbidden extends HTTPError {
}

export class BadRequest extends HTTPError {
}

export class UnknownError extends HTTPError {
}

// @ts-ignore
export class RequestProceedError extends Error {
}