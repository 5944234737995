import React, {useEffect, useState} from 'react';
import {CFormCheck} from '@coreui/react';

import Pagination from '../../../../../Admin/PaginationLegacy/PaginationLegacy';
import Loader from '../../../../../common/Loader/ContainerLoader'

import {timestamp} from '../../../../../../utils/data/td';

import API from '../../../../../../api';
import UserInfoById from '../../../../../../types/api/Admin/Users/UserInfoById';
import {IUserInventory} from '../../../../../../types/api/Admin/ActivityEvents/ActivityEvent';

import {
  OverflowContainer,
  TableBody,
  TableHead,
  TableTd,
  TableTh,
  TableTr,
  WrapperTable,
} from '../../../../../../pages/Admin/styles';
import {ModalWrapper, ModalTitle, ModalRow, ModalText} from '../../../../ModalUtils/styles';
import {ImageUrl} from '../../../../../../pages/Admin/styles';

interface IUserActivityEvents {
    userInfo: UserInfoById
    userId: string;
}

const UserActivityEvents = ({userInfo, userId}: IUserActivityEvents) => {
  const [userActivityEvents, setUserActivityEvents] = useState<IUserInventory[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [includeNotGranted, setIncludeNotGranted] = useState<boolean>(false);

  const getData = async (page: number = 1, includeNotGranted: boolean = false) => {
    const response = await API.admin.activityEvents.getUserRewardEvent(userId, includeNotGranted, page);
    if (!response) {
      return
    }
    setUserActivityEvents(response.data.data);
    setPage(response.data.page);
    setTotalPages(response.data.totalPages);

    setLoaded(true)
  }

  useEffect(() => {
    getData(page, includeNotGranted).catch(console.error);
  }, [page, includeNotGranted]);

  return (
    <ModalWrapper>
      <ModalTitle className="title-bold" marginBottom={8}>
        {userInfo?.username || 'Username'}
      </ModalTitle>
      <ModalText marginBottom={20} className="title-bold"
        style={{width: '100%', textAlign: 'center'}}>
                Activity Events Rewards
      </ModalText>

      <ModalRow marginBottom={20} style={{justifyContent: 'flex-start'}}>
        <ModalText marginBottom={0} style={{marginRight: '12px'}}>
                    Show <b>Not Granted</b>
        </ModalText>
        <CFormCheck checked={includeNotGranted}
          onClick={() => {
            setPage(1)
            setIncludeNotGranted(prevState => !prevState)
          }}/>
      </ModalRow>

      {loaded ? <ModalRow marginBottom={0} style={{alignItems: 'flex-start'}}>
        <OverflowContainer>
          <WrapperTable>
            <TableHead>
              <TableTr>
                <TableTh>Target</TableTh>
                <TableTh>Progress</TableTh>
                <TableTh>Granted</TableTh>
                <TableTh>Reward</TableTh>
              </TableTr>
            </TableHead>
            <TableBody>
              {userActivityEvents.map((reward, index) => (
                <TableTr key={reward?.target || index.toString()} active={true}>
                  <TableTd>{reward?.target || '-'}</TableTd>
                  <TableTd>{reward?.progress || 0}</TableTd>
                  {reward.grantedAt ? timestamp(reward.grantedAt) : <TableTd>-</TableTd>}
                  <TableTd>
                    {reward.imageUrl ?
                      <ImageUrl src={reward.imageUrl}
                        alt={`imageUrl-${index}`}
                        style={{display: 'inline-flex'}}/> : '-'}
                  </TableTd>
                </TableTr>
              ))}
            </TableBody>
          </WrapperTable>
        </OverflowContainer>
      </ModalRow> : <Loader/>}
      {(totalPages >= 2 && loaded) &&
                <Pagination page={page} pageCount={totalPages} getData={getData} style={{marginTop: '8px'}}/>}
    </ModalWrapper>
  );
};

export default UserActivityEvents;
