import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';
import GameplayTask, {
  CompBulkActions,
  EditGameplayTask,
  GameplayTaskValues
} from '../../../types/api/Game/GameplayTask';
import MessageResponse from '../../../types/api/MessageResponse';

export async function getFilterTasks(
  page: number = 1, perPage: number = 20, {name, types, statisticIds, inventoryClassIds}: GameplayTaskValues
): Promise<AxiosResponse<PaginatedResponse<GameplayTask>>> {
  return makeRequest('POST', '/api/admin/game/gameplay-tasks/filter', {
    page, perPage, name, types,
    statisticIds: statisticIds?.map((item) => item.value),
    inventoryClassIds: inventoryClassIds?.map((item) => item.value),
  });
}

export async function getTaskTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/gameplay-tasks/types');
}

export async function deleteGameplayTask(id: string) {
  return makeRequest('DELETE', `/api/admin/game/gameplay-tasks/${id}`);
}

export async function createTask({
  type, name, description, statistic, goal, weight,
}: EditGameplayTask): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', '/api/admin/game/gameplay-tasks', {
    type: type.value, name, description, statisticId: statistic?.value, goal, weight,
  });
}

export async function updateTask({
  id, type, name, description, statistic, goal, weight,
}: EditGameplayTask): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/game/gameplay-tasks/${id}`, {
    type: type.value, name, description, statisticId: statistic?.value, goal, weight,
  });
}

export async function getCompBulkActions(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/gameplay-tasks/compatibilities-bulk-actions');
}

export async function saveCompatibilities(
  gameplayTaskId: string, compatibilities: { inventoryClassId: string, compatible: boolean }[]
) {
  return makeRequest('POST', `/api/admin/game/gameplay-tasks/${gameplayTaskId}/compatibilities`, {compatibilities});
}

export async function saveCompatibilitiesBulkActions({
  action, ids, compatibilities, repCompatibilities
}: CompBulkActions) {
  return makeRequest('POST', '/api/admin/game/gameplay-tasks/compatibilities-bulk-actions', {
    action, gameplayTaskIds: ids,
    compatibilities: compatibilities ? compatibilities.map(item => ({inventoryClassId: item.inventoryClassId?.value})) :
      repCompatibilities ? repCompatibilities.map(item => ({
        inventoryClassId: item.inventoryClassId?.value,
        replaceInventoryClassId: item.replaceInventoryClassId?.value
      })) : undefined,
  });
}