import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import ITwitchExtension from '../../../types/api/Admin/TwitchExtension/TwitchExtension';
import {IGeneralTypes} from '../../../types/api/Admin/IGeneralTypes';

export async function getTwitchRewards(page: number = 1, perPage: number = 20): Promise<AxiosResponse<PaginatedResponse<ITwitchExtension>>> {
  return makeRequest('GET', `/api/admin/twitch-extension/rewards?page=${page}&perPage=${perPage}`);
}

export async function getTwitchRewardTypes(): Promise<AxiosResponse<IGeneralTypes>> {
  return makeRequest('GET', '/api/admin/twitch-extension/reward-types');
}

export async function createTwitchReward(type: number, itemId: string) {
  return makeRequest('POST', '/api/admin/twitch-extension/rewards', {type, itemId});
}

export async function updateTwitchReward(rewardId: string, type: number, itemId: string) {
  return makeRequest('POST', `/api/admin/twitch-extension/rewards/${rewardId}`, {type, itemId});
}

export async function deleteTwitchReward(rewardId: string) {
  return makeRequest('DELETE', `/api/admin/twitch-extension/rewards/${rewardId}`,);
}