import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import FirstMatch, {
  IEditLevelState,
  IEditState,
  MatchRewardsItems,
  MatchRewardsLevel
} from '../../../types/api/Game/MatchReward';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';
import {EditItemsState} from '../../../types/api/Game/MatchReward';
import {number} from 'prop-types';

export async function getFirstMatches(filtersValues: {
    type: { id: number | null, label: string }
}): Promise<AxiosResponse<FirstMatch[]>> {
  return makeRequest('GET', `/api/admin/game/match-rewards/first?characterType=${filtersValues.type?.id ?? ''}`);
}

export async function getRewardsTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/match-rewards/types');
}

export async function getRewardsAmountsTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/match-rewards/amounts-types');
}

export async function deleteFirstMatch(firstMatchRewardId: string) {
  return makeRequest('DELETE', `/api/admin/game/match-rewards/first/${firstMatchRewardId}`);
}

export async function getMatchRewardsItems(filtersValues: {
    type: { id: number | null, label: string }
}): Promise<AxiosResponse<MatchRewardsItems[]>> {
  return makeRequest('GET', `/api/admin/game/match-rewards/items?characterType=${filtersValues.type?.id ?? ''}`);
}

export async function createMatchRewardsItem({characterType, classValues, weight}: EditItemsState) {
  return makeRequest('POST', '/api/admin/game/match-rewards/items', {
    characterType, classId: classValues?.value, weight
  });
}

export async function updateMatchRewardsItem({id, characterType, classValues, weight}: EditItemsState) {
  return makeRequest('POST', `/api/admin/game/match-rewards/items/${id}`, {
    characterType, classId: classValues?.value, weight
  });
}

export async function deleteMatchRewardsItem(id: string) {
  return makeRequest('DELETE', `/api/admin/game/match-rewards/items/${id}`);
}

export async function createFirstMatchRewards({characterType, match, rewards}: IEditState) {
  return makeRequest('POST', '/api/admin/game/match-rewards/first', {
    characterType, match, rewards
  });
}

export async function updateFirstMatchRewards(firstMatchRewardId: string, rewards: any) {
  return makeRequest('POST', `/api/admin/game/match-rewards/first/${firstMatchRewardId}`, rewards);
}

export async function deleteFirstMatchRewards(id: string) {
  return makeRequest('DELETE', `/api/admin/game/match-rewards/first/${id}`);
}

export async function deleteAllFirstMatchRewards(match: number) {
  return makeRequest('DELETE', '/api/admin/game/match-rewards/first', {match});
}

export async function getLevelMatchRewards(filtersValues: {
    type: { id: number | null, label: string }
}): Promise<AxiosResponse<MatchRewardsLevel[]>> {
  return makeRequest('GET', `/api/admin/game/match-rewards/level?characterType=${filtersValues.type?.id ?? ''}`);
}

export async function deleteLevelMatchRewards(id: string) {
  return makeRequest('DELETE', `/api/admin/game/match-rewards/level/${id}`);
}

export async function deleteAllLevelMatchRewards(lvlFrom: number, lvlTo: number, characterType: number) {
  return makeRequest('DELETE', '/api/admin/game/match-rewards/level', {lvlFrom, lvlTo, characterType});
}

export async function createLevelMatchRewards({characterType, lvlFrom, lvlTo, rewards}: IEditLevelState) {
  return makeRequest('POST', '/api/admin/game/match-rewards/level', {
    characterType, lvlFrom, lvlTo, rewards
  });
}

export async function updateLevelMatchRewards(levelMatchRewardId: string, rewards: any) {
  return makeRequest('POST', `/api/admin/game/match-rewards/level/${levelMatchRewardId}`, rewards);
}