import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import IDropdownValue from '../../../types/api/Admin/IGeneralTypes';
import MessageResponse from '../../../types/api/MessageResponse';
import ReferralEventValues, {
  listParticipant,
  Participant,
  ReferralEvent
} from '../../../types/api/Admin/Users/ReferralEvent';

export async function filterReferralEvents(
  page: number = 1, perPage: number = 20, {name, active}: ReferralEventValues,
): Promise<AxiosResponse<PaginatedResponse<ReferralEvent>>> {
  return makeRequest('POST', '/api/admin/referral-events/filter', {
    page, perPage, name, active: active ? active : undefined
  });
}

export async function getActions(): Promise<AxiosResponse<IDropdownValue[]>> {
  return makeRequest('GET', '/api/admin/referral-events/actions');
}

export async function listParticipants(
  page: number = 1, perPage: number = 20, {
    referralEventId,
    participantCompletedActions,
    referralCompletedActions
  }: listParticipant,
): Promise<AxiosResponse<PaginatedResponse<Participant>>> {
  return makeRequest('POST', `/api/admin/referral-events/${referralEventId}/participants/filter`, {
    page, perPage, participantCompletedActions, referralCompletedActions
  });
}

export async function createReferralEvent(name: string, start: number, end: number): Promise<AxiosResponse<MessageResponse>> {
  return await makeRequest('POST', '/api/admin/referral-events', {name, start, end});
}

export async function updateReferralEvent(referralEventId: string, name: string, start: number, end: number): Promise<AxiosResponse<MessageResponse>> {
  return await makeRequest('POST', `/api/admin/referral-events/${referralEventId}`, {name, start, end});
}

export async function deleteReferralEvent(referralEventId: string): Promise<void> {
  await makeRequest('DELETE', `/api/admin/referral-events/${referralEventId}`);
}