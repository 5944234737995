import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import GameMissions from '../../../types/api/Admin/GameMissions/GameMissions';
import {GAME_MISSION_TYPES} from '../../../pages/Admin/Game/GameMissions/GameMissions';
import MissionRanks from '../../../types/api/Admin/GameMissions/MissionRanks';

export async function getMissionRanks(): Promise<AxiosResponse<MissionRanks[]>> {
  return await makeRequest('GET', '/api/admin/game/missions/ranks');
}

export async function saveMissionRanks(ranks: number[]): Promise<AxiosResponse<{ message: string }>> {
  return await makeRequest('POST', '/api/admin/game/missions/ranks', ranks);
}

export async function getGameMissions(type: GAME_MISSION_TYPES): Promise<AxiosResponse<GameMissions[]>> {
  return await makeRequest('GET', `/api/admin/game/missions?type=${type}`);
}

export async function create(
  statisticId: string,
  type: string,
  aggregationType: string,
  text: string,
  goals: number[],
  reward: number) {
  return makeRequest('POST', '/api/admin/game/missions',
    {statisticId, type, text, goals, reward, aggregationType});
}

export async function update(
  missionId: string,
  statisticId: string,
  aggregationType: string,
  text: string,
  goals: number[],
  reward: number) {
  return makeRequest('POST', `/api/admin/game/missions/${missionId}/data`,
    {statisticId, aggregationType, text, goals, reward});
}

export async function destroy(missionId: string) {
  return makeRequest('DELETE', `/api/admin/game/missions/${missionId}`);
}