//Auth
import * as alphaKeyRequests from './Auth/alphaKeyRequests';
import * as users from './Auth/users';
import * as referralRewards from './Auth/referralRewards';
import * as levelRewards from './Auth/levelRewards';
import * as avatars from './Auth/avatars';
import * as alphaKey from './Auth/alphaKey';
import * as articles from './Auth/articles';
import * as achievements from './Auth/achievements';
import * as missions from './Auth/missions';
import * as activityLog from './Auth/activityLog';
import * as referralEvents from './Auth/referralEvents';
import * as twitter from './Auth/twitter';
//Banking
import * as collections from './Banking/collections';
import * as wallets from './Banking/wallets';
import * as transactions from './Banking/transactions';
import * as economy from './Banking/economy';
//Game
import * as activityEvents from './Game/activityEvents';
import * as features from './Game/features';
import * as gameSessions from './Game/gameSessions';
import * as gameVersions from './Game/gameVersions';
import * as inventories from './Game/inventories';
import * as inventoryTypes from './Game/inventoryTypes';
import * as gameMissions from './Game/gameMissions';
import * as passes from './Game/passes';
import * as plannedPlaytimes from './Game/plannedPlaytimes';
import * as regions from './Game/regions';
import * as resetStatistics from './Game/resetStatistics';
import * as statistics from './Game/statistics';
import * as tournaments from './Game/tournaments';
import * as twitchExtension from './Game/twitchExtension';
import * as balance from './Game/balance';
import * as perks from './Game/perks';
import * as imageFormats from './Game/imageFormats';
import * as characterAttributes from './Game/characterAttributes';
import * as mergeLines from './Game/mergeLines';
import * as inventoryClasses from './Game/inventoryClasses';
import * as gameplayTasks from './Game/gameplayTasks';
import * as matchRewards from './Game/matchRewards';

const ADMIN_API = {
  avatars,
  transactions,
  economy,
  collections,
  users,
  wallets,
  referralRewards,
  alphaKeyRequests,
  alphaKey,
  articles,
  achievements,
  missions,
  levelRewards,
  activityEvents,
  features,
  gameSessions,
  gameVersions,
  inventories,
  inventoryTypes,
  gameMissions,
  passes,
  plannedPlaytimes,
  regions,
  resetStatistics,
  statistics,
  tournaments,
  twitchExtension,
  activityLog,
  balance,
  perks,
  referralEvents,
  twitter,
  imageFormats,
  characterAttributes,
  mergeLines,
  inventoryClasses,
  gameplayTasks,
  matchRewards,
};

export default ADMIN_API;
