import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import Statistics from '../../../types/api/Admin/Statistics/Statistics';
import IDropdownResults from '../../../types/api/Admin/ReferralRewards/DropdownResults';
import IDropdownResultsByName from '../../../types/api/Admin/ReferralRewards/DropdownResults';

export async function listStatistics(
  page: number = 1,
  perPage: number = 20,
  segments: (string | null)[] | [] = [],
  displayed?: boolean,
): Promise<AxiosResponse<PaginatedResponse<Statistics>>> {
  return makeRequest('POST', '/api/admin/game/statistics/filtered', {
    page,
    perPage,
    segments,
    displayed,
  });
}

export async function possibleSegmentValues(
  otherSegments: (string | null)[] | [] = [],
  segment: number,
): Promise<AxiosResponse<(string | null)[]>> {
  return makeRequest('POST', '/api/admin/game/statistics/possible-segment-values', {
    otherSegments,
    segment,
  });
}

export async function createStatistic(name: string, segments: (string | null)[] | [], displayed: boolean, displayOrder?: number) {
  return makeRequest('POST', '/api/admin/game/statistics', {
    name,
    segments,
    displayed,
    displayOrder,
  });
}

export async function updateStatistic(statisticId: string, name: string, segments: (string | null)[] | [], displayed: boolean, displayOrder?: number) {
  return makeRequest('POST', `/api/admin/game/statistics/${statisticId}`, {
    name,
    segments,
    displayed,
    displayOrder,
  });
}

export async function deleteStatistic(statisticId: string) {
  return makeRequest('DELETE', `/api/admin/game/statistics/${statisticId}`);
}

export async function segmentsLimit(): Promise<AxiosResponse<{ limit: number }>> {
  return makeRequest('GET', '/api/admin/game/statistics/segments-limit');
}

export async function searchStatistics(name: string, type?: number): Promise<AxiosResponse<IDropdownResults[]>> {
  if (Number(type) >= 0) {
    return makeRequest('GET', `/api/admin/game/statistics/search?query=${name}&type=${type}`);
  } else {
    return makeRequest('GET', `/api/admin/game/statistics/search?query=${name}`);
  }
}