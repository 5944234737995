import React, {useEffect, useState} from 'react';
import {CButton} from '@coreui/react';

import Modal from '../../../../Modal';
import UserTournamentNominations
  from '../../../../Admin/common/UserInfo/UserTournaments/UserTournamentNominations'

import Loader from '../../../../../common/Loader/ContainerLoader'
import Pagination from '../../../../../Admin/PaginationLegacy/PaginationLegacy';

import {timestamp} from '../../../../../../utils/data/td';

import API from '../../../../../../api';
import UserInfoById from '../../../../../../types/api/Admin/Users/UserInfoById';
import IUserTournament from '../../../../../../types/api/Admin/Users/UserTournament';

import {
  OverflowContainer,
  TableBody,
  TableHead,
  TableTd,
  TableTh,
  TableTr,
  WrapperTable,
} from '../../../../../../pages/Admin/styles';
import {ModalWrapper, ModalTitle, ModalRow, ModalText} from '../../../../ModalUtils/styles';

interface IUserTournaments {
    userInfo: UserInfoById
    userId: string;
}

const UserTournaments = ({userInfo, userId}: IUserTournaments) => {
  const [userTournaments, setUserTournaments] = useState<IUserTournament[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [selectedTournament, setSelectedTournament] = useState<IUserTournament | null>(null);
  const [nominationModalVisible, setNominationModalVisible] = useState<boolean>(false);

  const getData = async (page: number = 1) => {
    const response = await API.admin.tournaments.getUserTournament(userId, page);
    if (!response) {
      return
    }
    setUserTournaments(response.data.data);
    setPage(response.data.page);
    setTotalPages(response.data.totalPages);

    setLoaded(true)
  }

  useEffect(() => {
    getData(page).catch(console.error);
  }, [page]);

  return (
    <ModalWrapper>
      <ModalTitle className="title-bold" marginBottom={8}>
        {userInfo?.username || 'Username'}
      </ModalTitle>
      <ModalText marginBottom={20} className="title-bold"
        style={{width: '100%', textAlign: 'center'}}>
                Tournaments
      </ModalText>
      {loaded ? <ModalRow marginBottom={0} style={{alignItems: 'flex-start'}}>
        <OverflowContainer>
          <WrapperTable>
            <TableHead>
              <TableTr>
                <TableTh>Tournament Name</TableTh>
                <TableTh>Start</TableTh>
                <TableTh>End</TableTh>
                <TableTh>Actions</TableTh>
              </TableTr>
            </TableHead>
            <TableBody>
              {userTournaments.map((tournament, index) => (
                <TableTr key={tournament?.id || index.toString()} active={true}>
                  <TableTd>{tournament?.name || '-'}</TableTd>
                  {tournament.start ? timestamp(tournament.start) : <TableTd>-</TableTd>}
                  {tournament.end ? timestamp(tournament.end) : <TableTd>-</TableTd>}
                  <TableTd>
                    <CButton
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedTournament(tournament);
                        setNominationModalVisible(true);
                      }}
                      color="warning" size="sm"
                    >
                                            Nominations
                    </CButton>
                  </TableTd>
                </TableTr>
              ))}
            </TableBody>
          </WrapperTable>
        </OverflowContainer>
      </ModalRow> : <Loader/>}
      {(totalPages >= 2 && loaded) &&
                <Pagination page={page} pageCount={totalPages} getData={getData} style={{marginTop: '8px'}}/>}

      {selectedTournament && <Modal
        isOpen={nominationModalVisible}
        onClose={() => setNominationModalVisible(false)}
      >
        <UserTournamentNominations
          userId={userId}
          userInfo={userInfo}
          userTournaments={selectedTournament}
        />
      </Modal>}
    </ModalWrapper>
  );
};

export default UserTournaments;
