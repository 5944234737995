import {AxiosResponse} from 'axios';

import makeRequest, {getStreamResponsePost} from '../../request';

import AlphaKeyRequestFilters from '../../../types/api/Admin/AlphaKeyRequests/AlphaKeyRequestFilters';
import AlphaKeyRequest from '../../../types/api/Admin/AlphaKeyRequests/AlphaKeyRequest';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import AlphaKeyRequestFiltersSelected from '../../../types/api/Admin/AlphaKeyRequests/AlphaKeyRequestFiltersSelected';
import {QUESTIONS_FILTERS, QUESTIONS_FILTERS_NAMES} from '../../../types/api/constants';

export async function list(
  page: number = 1,
  perPage: number = 20,
  filters?: AlphaKeyRequestFiltersSelected,
): Promise<AxiosResponse<PaginatedResponse<AlphaKeyRequest>>> {
  if (filters && Object.keys(filters).length) {
    return listFiltered(page, perPage, filters);
  }
  return makeRequest(
    'GET',
    `/api/admin/alpha-key-requests?page=${page}&perPage=${perPage}`,
  );
}

async function listFiltered(
  page: number = 1,
  perPage: number = 20,
  filters: AlphaKeyRequestFiltersSelected,
): Promise<AxiosResponse<PaginatedResponse<AlphaKeyRequest>>> {
  const body: {
        [key: string]: string[];
    } = {};
  for (const key of Object.keys(filters)) {
    // @ts-ignore
    if (!QUESTIONS_FILTERS[key]) {
      continue;
    }
    // @ts-ignore
    body[QUESTIONS_FILTERS_NAMES[key]] = filters[key];
  }

  return makeRequest(
    'POST',
    `/api/admin/alpha-key-requests/filter?page=${page}&perPage=${perPage}`,
    body,
  );
}

export async function getFilters(): Promise<AxiosResponse<AlphaKeyRequestFilters>> {
  return makeRequest('GET', '/api/admin/alpha-key-requests/filters');
}

export async function prepareAlphaKeys(
  filtersValues: AlphaKeyRequestFiltersSelected,
  selectedIds: string[],
  email: string,
  link: string,
) {
  if (selectedIds.length) {
    return makeRequest('POST', '/api/admin/alpha-key-requests/assign-keys/prepare', {
      'selectedIds': selectedIds,
    });
  } else {
    return makeRequest('POST', '/api/admin/alpha-key-requests/assign-keys/prepare', {
      'contentCreator': filtersValues?.CONTENT_CREATOR,
      'device': filtersValues?.DEVICE,
      'age': filtersValues?.AGE,
      'games': filtersValues?.GAMES,
      'email': email,
      'link': link,
    });
  }
}

export async function assignKeys(
  ids: string[],
  hash: string,
  nonce: string,
) {
  return makeRequest('POST', '/api/admin/alpha-key-requests/assign-keys', {
    'ids': ids,
    'hash': hash,
    'nonce': nonce,
  });
}

export async function assignToRegistered(limit?: number) {
  return getStreamResponsePost('/api/admin/alpha-key-requests/assign-keys-to-registered', limit ? {limit} : undefined);
}