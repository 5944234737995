import React, {useEffect, useState} from 'react';
import {CButton, CFormCheck} from '@coreui/react';
import {cilTrash} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import Modal from '../../../../Modal';
import GrantGiftModalContent from '../../GrantGiftModalContent/GrantGiftModalContent';
import ActionConfirmationModalContent
  from '../../../../common/ActionConfirmationModalContent/ActionConfirmationModalContent';

import Pagination from '../../../../../Admin/PaginationLegacy/PaginationLegacy';
import Loader from '../../../../../common/Loader/ContainerLoader'
import ActionButton from '../../../../../Admin/ActionButton/ActionButton';

import {bool} from '../../../../../../utils/data/td';

import API from '../../../../../../api';
import UserInfoById from '../../../../../../types/api/Admin/Users/UserInfoById';
import IUserInventory from '../../../../../../types/api/Admin/Users/UserInventory';

import {
  OverflowContainer,
  TableBody,
  TableHead,
  TableTd,
  TableTh,
  TableTr,
  WrapperTable,
} from '../../../../../../pages/Admin/styles';
import {ModalWrapper, ModalTitle, ModalRow, ModalText} from '../../../../ModalUtils/styles';
import {ImageUrl} from '../../../../../../pages/Admin/styles';

interface IUserInventoryPage {
    userInfo: UserInfoById
    userId: string;
}

const UserInventory = ({userInfo, userId}: IUserInventoryPage) => {
  const [userInventory, setUserInventory] = useState<IUserInventory[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState<string>('all');
  const [selectedInventoryId, setSelectedInventoryId] = useState<string>('');

  const [grantModalVisible, setGrantModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const getData = async (page: number = 1, selectedType: string = 'all') => {
    const response = await API.admin.inventories.getUserInventory(userId, selectedType, page);
    if (!response) {
      return
    }
    setUserInventory(response.data.data);
    setPage(response.data.page);
    setTotalPages(response.data.totalPages);

    setLoaded(true)
  }

  const deleteInventory = async () => {
    try {
      await API.admin.inventories.deleteUserInventory(userId, selectedInventoryId);
      await getData(page, selectedType).catch(console.error);
      window.addToast('success', '', 'Inventory deleted', 1500, '#1FBF42');
    } catch (e: any) {
      window.addToast('danger', 'Error', `${e ? e.message : 'Failed. Please try again later'}`);
    } finally {
      setDeleteModalVisible(false)
      setSelectedInventoryId('')
    }
  };

  useEffect(() => {
    getData(page, selectedType).catch(console.error);
  }, [page]);

  useEffect(() => {
    getData(1, selectedType).catch(console.error);
  }, [selectedType]);

  return (
    <ModalWrapper>
      <ModalTitle className="title-bold" marginBottom={8}>
        {userInfo?.username || 'Username'}
      </ModalTitle>
      <ModalText marginBottom={20} className="title-bold"
        style={{width: '100%', textAlign: 'center'}}>
                Inventory
      </ModalText>
      <ModalRow>
        <ModalRow style={{maxWidth: '230px'}}>
          <CFormCheck type="radio" name="displayable-group" id="all" value="all" label="All"
            checked={selectedType === 'all'}
            onChange={(e) => setSelectedType(e.target.value)}/>
          <CFormCheck type="radio" name="displayable-group" id="asset" value="asset" label="Asset"
            checked={selectedType === 'asset'}
            onChange={(e) => setSelectedType(e.target.value)}/>
          <CFormCheck type="radio" name="displayable-group" id="inventory" value="inventory" label="Inventory"
            checked={selectedType === 'inventory'}
            onChange={(e) => setSelectedType(e.target.value)}/>
        </ModalRow>
        <ModalRow justifyContent='flex-end' style={{width: 'auto', marginLeft: '8px'}}>
          <ActionButton
            onClick={(event) => {
              event.stopPropagation();
              setGrantModalVisible(true)
            }}
            icon={7}
          />
        </ModalRow>
      </ModalRow>
      {loaded ? <ModalRow marginBottom={0} style={{alignItems: 'flex-start', minHeight: '405px'}}>
        <OverflowContainer>
          <WrapperTable>
            <TableHead>
              <TableTr>
                <TableTh>Name</TableTh>
                <TableTh>NFT</TableTh>
                <TableTh>Image</TableTh>
                <TableTh>Actions</TableTh>
              </TableTr>
            </TableHead>
            <TableBody>
              {userInventory.map((inventory, index) => (
                <TableTr key={inventory?.id || index.toString()} active={true}>
                  <TableTd>{inventory?.name || '-'}</TableTd>
                  {bool(Boolean(inventory.assetId))}
                  <TableTd>
                    {inventory.imageUrl ?
                      <ImageUrl src={inventory.imageUrl}
                        alt={`imageUrl-${index}`}
                        style={{display: 'inline-flex'}}/> : '-'}
                  </TableTd>
                  <TableTd>
                    <CButton
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedInventoryId(inventory?.id);
                        setDeleteModalVisible(true);
                      }}
                      disabled={Boolean(inventory.assetId)}
                      color="danger"
                      size="sm"
                    >
                      <CIcon size="sm" icon={cilTrash}/>
                    </CButton>
                  </TableTd>
                </TableTr>
              ))}
            </TableBody>
          </WrapperTable>
        </OverflowContainer>
      </ModalRow> : <Loader/>}
      {(totalPages >= 2 && loaded) &&
                <Pagination page={page} pageCount={totalPages} getData={getData} getDataArgs={selectedType}
                  style={{marginTop: '8px'}}
                  isModal={true}/>}

      <Modal
        isOpen={grantModalVisible}
        onClose={() => setGrantModalVisible(false)}
      >
        <GrantGiftModalContent
          onClose={() => setGrantModalVisible(false)}
          itemId={userId}
          grantFunction={API.admin.inventories.grantInventoryForUserInfo}
          searchFunction={API.admin.inventories.searchInventoriesByName}
          modalTitle='Grant Inventory'
          getData={() => getData(page, selectedType)}
        />
      </Modal>

      {selectedInventoryId && <Modal
        isOpen={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
      >
        <ActionConfirmationModalContent
          onClose={() => setDeleteModalVisible(false)}
          actionConfirmation={deleteInventory}

          modalTitle={'Delete Inventory'}
          modalText={'Are you sure that you want to delete this Inventory?'}
          buttonTitle={'Delete'}
        />
      </Modal>}
    </ModalWrapper>
  );
};
export default UserInventory;