import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import MergeLine, {
  IRollSettingsState,
  MergeLineState,
  MergeLineValues, RollSettings,
  Settings
} from '../../../types/api/Admin/MergeLines/MergeLines';
import MessageResponse from '../../../types/api/MessageResponse';

export async function getMergeLines(
  page: number = 1, perPage: number = 20, {name, type, belonging}: MergeLineValues
): Promise<AxiosResponse<PaginatedResponse<MergeLine>>> {
  return makeRequest('POST', '/api/admin/game/merge-lines/filter', {
    page, perPage, name, type: type?.id, belonging: belonging?.id
  })
}

export async function getMergeLineTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/merge-lines/types');
}

export async function getMergeLineBelongings(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/merge-lines/belongings');
}

export async function deleteMergeLine(mergeLineId: string) {
  return makeRequest('DELETE', `/api/admin/game/merge-lines/${mergeLineId}`);
}

export async function createMergeLine(
  {type, belonging, name, inventories, collections}: MergeLineState
): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', '/api/admin/game/merge-lines', {
    type: type.value, belonging: belonging.value, name,
    inventories: belonging.value === 0 ? inventories?.map((inv) => inv.value) : undefined,
    collections: belonging.value === 1 ? collections?.map((collection) => collection.value) : undefined,
  });
}

export async function updateMergeLine(
  {id, type, belonging, name, inventories, collections}: MergeLineState
): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/game/merge-lines/${id}`, {
    type: type.value, belonging: belonging.value, name,
    inventories: belonging.value === 0 ? inventories?.map((inv) => inv.value) : undefined,
    collections: belonging.value === 1 ? collections?.map((collection) => collection.value) : undefined,
  });
}

export async function getCostCurrencies(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/cost-currencies');
}

export async function getSettings(id = 'default') {
  return makeRequest('GET', `/api/admin/game/merge-lines/${id}/settings`);
}

export async function setSettings(id = 'default', settings: Settings[]): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', `/api/admin/game/merge-lines/${id}/settings`, {settings: settings});
}

export async function getRollSettingsTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeRequest('GET', '/api/admin/game/merge-lines/roll-settings/types');
}

export async function getAllRollSettings(type?: number): Promise<AxiosResponse<RollSettings[]>> {
  return makeRequest('GET', `/api/admin/game/merge-lines/roll-settings?type=${type}`);
}

export async function createRollSettings(settings: IRollSettingsState): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', '/api/admin/game/merge-lines/roll-settings', settings);
}

export async function updateRollSettings(settings: IRollSettingsState): Promise<AxiosResponse<MessageResponse>> {
  return makeRequest('POST', '/api/admin/game/merge-lines/roll-settings/update', settings);
}