import {ReactNode, useEffect, useState} from 'react';

import {UserContext} from './userContext';

import {getCookie} from '../../cookie';

import API from '../../api/index';
import {IWallet} from '../../types/api/Wallet';
import {IReward} from '../../types/api/Reward';

import * as localStorage from '../../localStorage';
import {LOCAL_STORAGE_NAMES} from '../../localStorage/types';

let activeUserStatusId: string = '10000000-0000-0000-0000-000000000001';

type ProfileAvatarProviderProps = {
    children: ReactNode
}

export const UserProvider = ({children}: ProfileAvatarProviderProps) => {
  const [userId, setUserId] = useState<string>('');
  const [currentAvatar, setCurrentAvatar] = useState<string>('');
  const [currentLevel, setCurrentLevel] = useState<number>(1);
  const [currentExperience, setCurrentExperience] = useState<number>(0);
  const [maxExperience, setMaxExperience] = useState<number>(0);
  const [currentUsername, setCurrentUsername] = useState<string>('');
  const [userDataLoaded, setUserDataLoaded] = useState<boolean>(false);
  const levelRewardsValue = localStorage.getItem(LOCAL_STORAGE_NAMES.LEVEL_REWARDS) as IReward[];
  const [levelRewards, setLevelRewards] = useState<IReward[]>(levelRewardsValue || []);
  const [levelRewardsVisible, setLevelRewardsVisible] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [acceptCookies, setAcceptCookies] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [refreshOpenlootConnection, setRefreshOpenlootConnection] = useState<boolean>(false);
  const [userWallets, setUserWallets] = useState<IWallet[]>([]);
  const [userFirstWallet, setUserFirstWallet] = useState<IWallet | null>(null);
  const [passwordResetRequired, setPasswordResetRequired] = useState<boolean>(false);
  const [updatePasswordModalVisible, setUpdatePasswordModalVisible] = useState<boolean>(false);
  const [userInfoModalVisible, setUserInfoModalVisible] = useState<boolean>(false);
  const [selectedModalUserId, setSelectedModalUserId] = useState<string>('');

  const saveUserId = async (id: string) => {
    if (userId || !id) {
      return;
    }

    setUserId(id);
  };

  const getUserInfo = async () => {
    if (!userId) {
      return;
    }

    const response = await API.users.get(userId);
    if (!response) {
      return;
    }

    setCurrentAvatar(response.data.avatar);
    setCurrentLevel(response.data.lvl);
    setCurrentExperience(response.data.exp);
    setMaxExperience(response.data.maxExp || 100);
    setCurrentUsername(response.data.account.username);
    setCurrentEmail(response.data.email);
    setUserWallets(response.data.wallets ? response.data.wallets : []);
    setUserFirstWallet(response.data.wallets ? response.data.wallets[0] : null);
    setPasswordResetRequired(response.data.passwordResetRequired);

    if (response.data.statusId !== activeUserStatusId) {
      setEmailVerified(false);
    } else {
      setEmailVerified(true);
    }

    if (response.data.roleNames.includes('ADMIN')) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    setUserDataLoaded(true);
  };

  const getLevelRewards = async () => {
    if (!userId) {
      return;
    }
    const response = await API.users.getLevelRewards(userId);
    if (!response) {
      return;
    }

    setLevelRewards(response.data);
    localStorage.setItem(LOCAL_STORAGE_NAMES.LEVEL_REWARDS, response.data);
  };

  useEffect(() => {
    if (window.loggedIn) {
      getUserInfo().catch(console.error);
    }
  }, [window.loggedIn, userId]);

  useEffect(() => {
    const acceptCookies = getCookie('acceptCookies');

    if (acceptCookies === 'true') {
      setAcceptCookies(true);
    } else {
      setAcceptCookies(false);
    }
  }, [window.loggedIn]);

  return (
    <UserContext.Provider
      value={{
        userId,
        setUserId,
        saveUserId,
        currentAvatar,
        setCurrentAvatar,
        currentLevel,
        setCurrentLevel,
        currentExperience,
        setCurrentExperience,
        maxExperience,
        setMaxExperience,
        levelRewards,
        setLevelRewards,
        levelRewardsVisible,
        setLevelRewardsVisible,
        getLevelRewards,
        currentUsername,
        setCurrentUsername,
        currentEmail,
        setCurrentEmail,
        emailVerified,
        setEmailVerified,
        userDataLoaded,
        setUserDataLoaded,
        acceptCookies,
        setAcceptCookies,
        isAdmin,
        setIsAdmin,
        refreshOpenlootConnection,
        setRefreshOpenlootConnection,
        userWallets,
        setUserWallets,
        userFirstWallet,
        setUserFirstWallet,
        getUserInfo,
        passwordResetRequired,
        setPasswordResetRequired,
        updatePasswordModalVisible,
        setUpdatePasswordModalVisible,
        userInfoModalVisible,
        setUserInfoModalVisible,
        selectedModalUserId,
        setSelectedModalUserId,
      }}>
      {children}
    </UserContext.Provider>
  );
};