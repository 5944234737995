import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import Missions from '../../../types/api/Admin/Missions/Missions';
import {MISSION_TYPES} from '../../../pages/Client/Main/Dashboard/Dashboard';

export async function getMissions(type: MISSION_TYPES): Promise<AxiosResponse<Missions[]>> {
  return await makeRequest('GET', `/api/admin/missions?type=${type}`);
}

export async function updateMission(
  missionId: string,
  exp: number,
  type: number,
  iteration: number,
  name: string,
  enabled: boolean,
  description: string,
): Promise<AxiosResponse<{ message: string }>> {
  return makeRequest('POST', `/api/admin/missions/${missionId}`,
    {exp, type, iteration, name, enabled, description});
}