import axios, {AxiosError, AxiosResponse} from 'axios';

import makeRequest from '../../request';
import error from '../../errors';

import config from '../../config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import Avatar from '../../../types/api/Admin/Users/Avatar';
import IDropdownResults from '../../../types/api/Admin/GameSessions/DropdownResults';

export async function listAvatars(
  page: number = 1,
  perPage: number = 20,
): Promise<AxiosResponse<PaginatedResponse<Avatar>>> {
  return makeRequest('GET', `/api/admin/avatars?page=${page}&perPage=${perPage}`);
}

export async function createAvatar(
  isDefault: boolean,
  name: string,
): Promise<AxiosResponse<{ id: string }>> {
  return makeRequest('POST', '/api/admin/avatars', {isDefault, name});
}

export async function updateAvatarImage(
  avatarId: string,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/admin/avatars/${avatarId}/image`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function updateAvatar(
  avatarId: string,
  isDefault: boolean,
  name: string,
): Promise<AxiosResponse<{ message: string }>> {
  return makeRequest('POST', `/api/admin/avatars/${avatarId}`, {isDefault, name});
}

export async function grantAvatar(userId: string, avatarId: string) {
  return makeRequest('POST', `/api/admin/avatars/${avatarId}/grant`, {
    userId,
  });
}

export async function deleteAvatar(avatarId: string) {
  return makeRequest('DELETE', `/api/admin/avatars/${avatarId}`);
}

export async function searchAvatarByName(name: string): Promise<AxiosResponse<IDropdownResults[]>> {
  return makeRequest('GET', `/api/admin/avatars/dropdown-results?name=${name}`);
}