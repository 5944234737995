import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import Region, {IFilterValues} from '../../../types/api/Admin/Regions/Regions';
import IDropdownItems from '../../../types/api/Admin/DropdownItems';

export async function getRegions(): Promise<AxiosResponse<Region[]>> {
  return makeRequest('GET', '/api/admin/game/regions');
}

export async function createRegion(name: string, shortcut: string, points: { lat: number, long: number }[]) {
  return await makeRequest('POST', '/api/admin/game/regions', {name: name, shortcut: shortcut, points: points});
}

export async function updateRegion(regionId: string, name: string, shortcut: string, points: {
    lat: number,
    long: number
}[]) {
  return await makeRequest('POST', `/api/admin/game/regions/${regionId}`, {
    name: name,
    shortcut: shortcut,
    points: points,
  });
}

export async function deleteRegion(regionId: string) {
  return await makeRequest('DELETE', `/api/admin/game/regions/${regionId}`);
}

export async function getPlayersCoordinates({
  types,
  start,
  end,
}: IFilterValues): Promise<AxiosResponse<[lat: number, long: number, type: number][]>> {
  return makeRequest('POST', '/api/admin/game/players/get-coordinates', {
    types: types || [],
    start: start || null,
    end: end || null,
  });
}

export async function getPlayersCoordinatesByIds(userIds: string[] | []): Promise<AxiosResponse<[lat: number, long: number, type: number][]>> {
  return makeRequest('POST', '/api/admin/game/players/get-coordinates-by-user-ids', {userIds});
}

export async function getPlayerTypes(): Promise<AxiosResponse<IDropdownItems[]>> {
  return makeRequest('GET', '/api/admin/game/players/types');
}