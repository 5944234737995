import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import IImageFormat, {EditImageFormat} from '../../../types/api/Admin/ImageFormats/ImageFormat';

export async function getImageFormats(): Promise<AxiosResponse<IImageFormat[]>> {
  return makeRequest('GET', '/api/admin/game/image-formats');
}

export async function createImageFormat({
  name, originalName, previewName,
  extensions, previewExtension,
  widthFrom, heightFrom, previewWidth,
  widthTo, heightTo, previewHeight,
}: EditImageFormat) {
  return makeRequest('POST', '/api/admin/game/image-formats', {
    name, originalName, previewName,
    extensions: extensions.map((extension) => extension.value),
    previewExtension: previewExtension?.value,
    widthFrom, heightFrom, previewWidth,
    widthTo, heightTo, previewHeight,
  });
}

export async function updateImageFormat(imageFormatId: string, {
  name, originalName, previewName,
  extensions, previewExtension,
  widthFrom, heightFrom, previewWidth,
  widthTo, heightTo, previewHeight,

}: EditImageFormat) {
  return makeRequest('POST', `/api/admin/game/image-formats/${imageFormatId}`, {
    name, originalName, previewName,
    extensions: extensions.map((extension) => extension.value),
    previewExtension: previewExtension?.value,
    widthFrom, heightFrom, previewWidth,
    widthTo, heightTo, previewHeight,
  });
}


export async function deleteImageFormat(imageFormatId: string) {
  return makeRequest('DELETE', `/api/admin/game/image-formats/${imageFormatId}`,);
}