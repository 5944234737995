import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import PlannedPlaytime from '../../../types/api/Admin/PlannedPlaytimes/PlannedPlaytimes';

export async function getPlannedPlaytimes(page: number = 1, perPage: number = 20): Promise<AxiosResponse<PaginatedResponse<PlannedPlaytime>>> {
  return makeRequest('GET', `/api/admin/game/playtimes?page=${page}&perPage=${perPage}`);
}

export async function createPlannedPlaytime(start: number, end: number) {
  return await makeRequest('POST', '/api/admin/game/playtimes', {start: start, end: end});
}

export async function updatePlannedPlaytime(playtimeId: string, start: number, end: number) {
  return await makeRequest('POST', `/api/admin/game/playtimes/${playtimeId}`, {start: start, end: end});
}

export async function deletePlannedPlaytime(playtimeId: string) {
  return await makeRequest('DELETE', `/api/admin/game/playtimes/${playtimeId}`);
}

export async function createPlannedPlaytimeSlot(playtimeId: string, start: number, end: number) {
  return await makeRequest('POST', `/api/admin/game/playtimes/${playtimeId}/slots`, {start: start, end: end});
}

export async function updatePlannedPlaytimeSlot(playtimeId: string, slotId: string, start: number, end: number) {
  return await makeRequest('POST', `/api/admin/game/playtimes/${playtimeId}/slots/${slotId}`, {
    start: start,
    end: end
  });
}

export async function deletePlannedPlaytimeSlot(playtimeId: string, slotId: string,) {
  return await makeRequest('DELETE', `/api/admin/game/playtimes/${playtimeId}/slots/${slotId}`)
}

export async function duplicatePlannedPlaytime(playtimeId: string, shift: number) {
  return await makeRequest('POST', `/api/admin/game/playtimes/${playtimeId}/duplicate-shifted`, {shift: shift});
}